<template>
 <div class="row h-100 justify-content-center">

    <div class="col-lg-10 col-xl-10">
      <div class="d-flex flex-column px-7 flex-wrap mt-5">
        <!--::Description-->
        <div class="row gutter-b">
          <div class="col-12 col-sm-12 col-lg-6 col-xl-6">
            <div class="card card-custom overlay mb-3 card-shadow">
              <div class="card-body p-0">
                <div class="overlay-wrapper">
                  <img :src="scenario.background ? scenario.background.uri  : '/assets/media/bipart/idea-default.svg'" alt="scenario" class="w-100 rounded"/>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-lg-6 col-xl-6">
            <div class="card card-custom  card-shadow mb-3">
              <div class="card-body">
                <div class="d-flex align-items-center mt-4 ml-4">
                  <a href="#" class="font-size-h2 text-dark font-weight-bolder mb-1">{{ scenario.title }}</a>
                </div>
              </div>
            </div>
            <div class="accordion accordion-solid accordion-panel accordion-svg-toggle" id="accordionChallenge">
              <div class="card mb-3 card-shadow">
                <b-button v-b-toggle.collapse-description class="btn btn-block btn-white text-primary p-4 text-left">
                  <span class="svg-icon svg-icon-primary svg-icon-2x">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    >
                    <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                    >
                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                        <path
                        d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961
                        C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686
                        20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429
                        12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721
                        L12.2928955,6.70710318 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        ></path>
                        <path
                        d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068
                        C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322
                        C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459
                        C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465
                        14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        opacity="0.3"
                        transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                        ></path>
                    </g>
                    </svg>
                  </span>
                  <span class="h4 ml-5 font-size-bolder">Descrizione</span>
                </b-button>
                <b-collapse id="collapse-description" visible>
                  <div class="card-body">
                    <div class="row p-5">
                      <div class="col-lg-12 col-sm-12" v-if="scenario.description">
                        <p class="card-text h4" v-html="scenario.description"></p>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
//import OrganizationMenu from "@/view/layout/common/OrganizationMenu.vue";
//import ProcessMenu from "@/view/layout/common/ProcessMenu";
import ApiService from "@/core/services/api.service";
//import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";

export default {
  name: "ScenarioDetail",
  data() {
    return {
      scenario: [],
      id: this.$route.params.id
    };
  },
  computed: {},
  components: {
    //OrganizationMenu,
    //KTQuickUser
  },
  mounted() {
    // set the tab from previous
    // this.setActivePreviousTab();

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Elenco Scenari", route: "/scenario-list" },
      { title: "Scenario" }
    ]);

    this.getData();
  },
  created() {},
  methods: {
    getData() {
      return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl("scenarios-expanded/" + this.id, true);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint)
          .then(res => {
            // console.log(res);
            if (res.status == 200) {
              this.scenario = res.data;
            }
          })
            .catch(() => {
          // .catch(({ error }) => {
          //   console.log(error);
            reject(0);
          });
      });
    }
  }
};
</script>

<style scoped></style>
